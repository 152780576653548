.table-container {
    color: white;
    justify-content: center;
    width: 100%;
    display: flex;
    margin-top: 4rem;
}

.tbody-tables img {
    width: 7rem;
    padding: 26px;
}

.delete-container i {
    cursor: pointer;
    padding: 4px;
}

