.password-container {
    max-width: 287px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 4rem;
    text-align: center;
}

.password-container h2 {
    text-align: center;
    margin-bottom: 30px;
    color: #333;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    color: #555;
    font-weight: bold;
}

.form-controll {
    width: 94%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
}

.alert {
    display: block;
    margin-top: 10px;
    padding: 10px;
    border-radius: 4px;
}

.alert-danger {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}

.alert-success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
}

.btn-primary {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: orange;
    border: 1px solid rgba(255, 119, 0, 0.723);
    border-radius: 4px;
    cursor: pointer;
    transition: 0.4s;
    margin-top: 1rem;
}

.btn-primary:hover {
    background-color: rgba(255, 85, 0, 0.968);
    border-color: orange;
}

@media (min-width:450px) {
    .password-container {
        max-width: 400px;
    }
}